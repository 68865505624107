import {get, set} from 'lodash';

function convertYupErrorsToFieldErrors(yupErrors) {
  return yupErrors.inner.reduce((errors, {path, message}) => {
    if (get(errors, path)) {
      set(errors, path, get(errors, path) + ' ' + message);
    } else {
      set(errors, path, message);
    }
    return errors;
  }, {});
}

export default async (values, validator) => {

  try {
    await validator.validate(values, {abortEarly: false})
  } catch (err) {
    return convertYupErrorsToFieldErrors(err);
  }
}
