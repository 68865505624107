import React from 'react'

import {Switch, Route} from 'react-router';
import configureStore, {history} from '../../configureStore';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {BlockDetail, BlockEdit, BlockRent, BlockBuy, BlockList} from '../../components/blocks';
import MyAccount from '../../components/MyAccount';
import Landing from '../../components/Landing';
import CommingSoon from "../../components/ComingSoon";
import firebase from "firebase";

import Home from '../Home'
import Grid from "@material-ui/core/Grid";

const store = configureStore();
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG.replace(/'/g, '"'));
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
firebase.auth().onAuthStateChanged(user => user ? localStorage.setItem('uid', user.uid) : localStorage.removeItem('uid'));


export default function MainContainer() {
  if (process.env.REACT_APP_ENV === 'production') {
    return <CommingSoon/>;
  }
  return (
    <Provider store={store}>
      <Grid>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/account' component={MyAccount}/>
            <Route exact path='/landing' component={Landing}/>
            <Route exact path='/blocks' component={BlockList}/>
            <Route path='/blocks/:id' component={
              ({match: {params: {id}}}) =>
                <ConnectedRouter history={history}>
                  <Switch>
                    <Route exact path={`/blocks/${id}`} component={BlockDetail}/>
                    <Route path={`/blocks/${id}/edit`} component={BlockEdit}/>
                    <Route path={`/blocks/${id}/rent`} component={BlockRent}/>
                    <Route path={`/blocks/${id}/buy`} component={BlockBuy}/>
                  </Switch>
                </ConnectedRouter>
            }/>
          </Switch>
        </ConnectedRouter>
      </Grid>
    </Provider>
  )
}
