import React, {useState} from 'react';
import {Form} from 'react-final-form';
import {TextField} from 'mui-rff';
import {Typography, Paper, Grid, Button, CssBaseline,} from '@material-ui/core';
import BuyValidator from './validators/buy-validator';
// **************** PangolinDEX Project Web3 Integration *********************//
import {JSBI} from '@pangolindex/sdk'
import { useActiveWeb3React } from '../../hooks'
import ConnectedButton from '../../avalaunch-web3/ConnectedButton'
import {useStoreCallback} from '../../avalaunch-web3/hooks/blockbuy'
// Transactions and Responses handlers
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {ButtonLight} from '../Button'
import { useWalletModalToggle } from '../../state/application/hooks'

export default ({match: {path}}) => {

  const { account, chainId, library } = useActiveWeb3React()
  //const toggleWalletModal = useWalletModalToggle()

  const [hash, setHash] = useState()
  const [error, setError] = useState()
  const [attempting, setAttempting] = useState(false)

  // monitor the status of the claim from contracts and txns
//  const claimPending = useIsTransactionPending(hash ?? '')
//  const claimConfirmed = hash && !claimPending
  //const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  //const addTransaction = useTransactionAdder()
  const {storeCallback} = useStoreCallback()

  function onSubmit(values) {
    var title = values.title;
    var image_url = values.image_url;
    var description = values.description;
    var buy_price = values.buy_price;
    var rent_price = values.rent_price;
    let args = [title,
                image_url,
                description,
                JSBI.BigInt(buy_price).toString(),
                JSBI.BigInt(rent_price).toString()]
    console.log("args: ", args)
    console.log("Title: ", title,
                 "ImageUrl; ", image_url,
                 "Description: ", description,
                 "Buy Price: ", buy_price,
                 "RentPrice: ", rent_price)

    console.log("Submitting Form...")
    setAttempting(true)
    console.log("attempting: ", attempting)

    storeCallback(...args)
      .then(hash => {
        console.log("Hash: ", hash)
        setAttempting(false)
        setHash(hash)
    }).catch(err => {
      console.log("Error; ", err)
      setAttempting(false)
      setError(err)
    })
  }

  const id = path.split('/')[2];
  return (
    <div style={{padding: 16, margin: 'auto', maxWidth: 600}}>
      <CssBaseline/>
      <Typography variant="h4" align="center" component="h1" gutterBottom>
        <span role="img" aria-label="flag">
          🏁
        </span>{' '}
        Buy Block {id}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{employed: true, stooge: 'larry'}}
        validate={BuyValidator}
        render={({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{padding: 16}}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Title"
                    name="title"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Image URL"
                    name="image_url"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Description"
                    name="description"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Buy price"
                    name="buy_price"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} key={0}>
                  <TextField
                    label="Rent price"
                    name="rent_price"
                    margin="none"
                    required={true}
                  />
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  {(!account) ? (
                    // If Metamask is not Connected
                    <ButtonLight >Connect your Wallet to Submit</ButtonLight>
                    //<ButtonLight onClick={toggleWalletModal}>Connect your Wallet to Submit</ButtonLight>
                  ) : (
                    // If Metamask IS connected
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}

                    >
                      Submit
                    </Button>
                  )}



                </Grid>
              </Grid>
            </Paper>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
}
