/* This example requires Tailwind CSS v2.0+ */
import {
  CloudUploadIcon,
  LockClosedIcon,
  RefreshIcon,
} from '@heroicons/react/outline';
import React from "react";

const features = [
  { name: 'Buy blocks ', description: 'Each block in the showcase is backed by a NFT.', icon: CloudUploadIcon },
  { name: 'Rent blocks', description: 'The owner of each block can lease them to other users, for any price and period.', icon: LockClosedIcon },
  { name: 'Sell blocks', description: 'Once you buy a block, you can sell it at anyone, at any price', icon: RefreshIcon },
]

export default function Example() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">A worldwide showcase</h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Show your NFTs to the world
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          If a tree falls in the forest and no one's around to hear it, does it make a sound? We don't think so. And so your NFTs. That is why we built Avalaunch, a showcase composed of blocks that you can own.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
