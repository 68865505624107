import * as React from 'react';
import {BlocksContext} from './blocks-context';
export default class BlocksProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      toggleShoppingMode: this.toggleShoppingMode.bind(this),
      shoppingMode: false,
    };
  }
  toggleShoppingMode() {
    this.setState({shoppingMode: !this.state.shoppingMode});
  }
  render() {
    return (
      <BlocksContext.Provider value={{
        blocksContext: {
          ...this.state
        }
      }}>
        {this.props.children}
      </BlocksContext.Provider>
    )
  }
}
