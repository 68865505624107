import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import {Link} from 'react-router-dom'

import { useActiveWeb3React } from '../../hooks'

import axios from "axios";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {AttachMoney} from "@material-ui/icons";
import {withBlocks} from "../../context/withBlocks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  media: {},
  card: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  blockItem: {
    padding: 10,
  },
}));


/*
To test Blockchain integration with NetworkConnector,
we are going to extract info from DontButMeme's NTFs.

https://etherscan.io/address/0xe4605d46fd0b3f8329d936a8b258d69276cba264#readContract
function: uri(Id)

*/


// Web3 integration:

//const ethers = require('ethers');

export default withBlocks(({block, ...props}) => {
  const context = useActiveWeb3React();
  const {blocksContext: {shoppingMode}} = props;
  const {
    connector,
    //library,
    chainId,
    account,
    //activate,
    // deactivate,
    // active,
    // error
  } = context;

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.blockItem}>
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={{pathname: `/blocks/${block.id}`, state: {block}}}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            image={block.img}
            className={classes.media}
            title="Contemplative Reptile"
          />
          <CardContent className={classes.overlay}>
            <Typography gutterBottom variant="h6" component="h6">
              {block.name}
            </Typography>
          </CardContent>
        </CardActionArea>
        {shoppingMode && <CardActions>
          <Grid container
                direction="row"
                justify="space-between"
                alignItems="center">
            <Typography variant="p" component="p" style={{paddingLeft: 10}}>
              Block #{block.id}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              to={{pathname: `/blocks/${block.id}`, state: {block}}}
              component={ Link }
              className={classes.button}
              startIcon={<AttachMoney/>}
            >
              {block.price}
            </Button>
          </Grid>
        </CardActions>}
      </Card>
    </Grid>
  );
});
