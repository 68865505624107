import * as React from 'react';
import {BlocksContext} from './blocks-context';
export function withBlocks(Component) {
  return function BlocksComponent(props) {
    return (
      <BlocksContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />
        }
      </BlocksContext.Consumer>
    )
  }
}
