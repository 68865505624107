import React, { Suspense } from 'react'
import styled from 'styled-components'

import {Switch, Route} from 'react-router';
import configureStore, {history} from '../configureStore';
import { ConnectedRouter } from 'connected-react-router';
import {Provider} from 'react-redux';
import {BlockDetail, BlockEdit, BlockRent, BlockBuy, BlockList} from '../components/blocks';
import MyAccount from '../components/MyAccount';
import Container from '@material-ui/core/Container';


import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'


import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'

import MainContainer from './MainContainer'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <URLWarning />
        <HeaderWrapper>
           <Header />
        </HeaderWrapper>
        <BodyWrapper style={{background: 'white'}}>
          {/*<Popups />*/}
             <Polling />
          <Web3ReactManager>
            <MainContainer />
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
