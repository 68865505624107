import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import BlockItem from "./BlockItem";
import {withBlocks} from "../../context/withBlocks";
import InfiniteScroll from 'react-infinite-scroller';
import firebase from "firebase";

// Web3 Integration.
//import {useWeb3React} from '@web3-react/core'
//import { useActiveWeb3React } from '../../hooks'
//import {injected} from '../../web3/connectors'
//import {injected, network} from '../../connectors'


import CircularProgress from "@material-ui/core/CircularProgress";
import BlockChunkItem from "./BlockChunkItem";

export default withBlocks((props) => {
  const {blocksContext: {shoppingMode}} = props;
  const [blocks, setblocks] = useState(undefined);
  // const context = useActiveWeb3React();
  // const {
  //   connector,
  //   //library,
  //   //chainId,
  //   account,
  //   //activate,
  //   // deactivate,
  //   // active,
  //   // error
  // } = context;
  //
  // console.log("Context useActiveWeb3React ", context)
  // console.log("Using Injected connector: ", connector == injected)
  // console.log("Using Network connector: ", connector == network)


  const loader = <div className="loader">Loading ...</div>;

  useEffect(() => {
    firebase.firestore().collection('blocks').get()
      .then(data => setblocks(data.docs.map(doc => ({
        id: doc.id,
        active: Math.random() > 0.5,
        price: (Math.random() * 100000).toFixed(0),
        img: 'https://material-ui.com/static/images/grid-list/breakfast.jpg',
        rentPrice: 3,
        buyPrice: 5,
        url: 'https://www.google.com',
        ...doc.data().data,
      }))));
  }, []);

  if (blocks === undefined)
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justify="center">
        <Grid item xs={3}>
          <CircularProgress/>
        </Grid>
      </Grid>);

  return (
    <div>
      {/*
        <div>
          Account in BlockList: {account}
          <br/>
          Is connected to Metamask: {connector == injected ? "True" : "False"}
        </div>
      */}

      <div>
        <InfiniteScroll
          pageStart={0}
          loader={loader}
        >
          <Grid container direction="row" justify="center" spacing={0}>
            {blocks.reduce((acc, block) => {
              const last = acc[acc.length - 1];
              if (block.active) return acc.push(block) && acc;
              if (Array.isArray(last)) {
                return last.push(block) && acc
              } else {
                return acc.push([block]) && acc
              }
            }, []).map(block =>
                Array.isArray(block) ? (shoppingMode ? <BlockChunkItem block={block}/> : null) : <BlockItem block={block}/>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>

  );
});
