// Forked from src/constants/index.ts

import { ChainId} from '@pangolindex/sdk'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'


// Avalaunch Integration
export const STORE_FORM_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.FUJI]: '0xb363db0261B8e0753B3fa10c27bF688eA9E160A4',
  [ChainId.AVALANCHE]: ZERO_ADDRESS
}
