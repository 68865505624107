import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from '@material-ui/core/styles';
import CardActions from "@material-ui/core/CardActions";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {VictoryChart, VictoryLine} from "victory";

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: '30%',
  },
});

function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default (props) => {

  const block = props.location.state.block;
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="h4" align="left" component="h1" gutterBottom>
        <span role="img" aria-label="flag">
          🏁
        </span>{' '}
          Block #{block.id}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CardActionArea component="div">
          <Card className={classes.card}>
            <Hidden xsDown>
              <CardMedia className={classes.cardMedia} image={block.img} title={block.title}/>
            </Hidden>
            <div className={classes.cardDetails}>
              <CardContent>
                <Typography component="h2" variant="h5">
                  {block.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {block.date}
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  {block.description}
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  <Grid item>
                    <Link to={{pathname: block.url}} target="_blank">
                      Link to Website
                    </Link>
                  </Grid>
                </Typography>
              </CardContent>
            </div>
          </Card>
        </CardActionArea>
        <CardActions>
          <div>
            <Button variant="contained" color="primary" to={`/blocks/${block.id}/buy`} component={ Link }>
              Buy (${block.rent_price || 1000} AVAX)
            </Button>
            <Button variant="contained" color="primary" to={`/blocks/${block.id}/rent`} component={ Link }>
              Rent (${block.rent_price || 1} AVAX)
            </Button>
          </div>
          <Button variant="contained" color="primary" href='' disabled>Report</Button>
        </CardActions>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" align="left" component="h1" gutterBottom>
          Price over time
        </Typography>
        <VictoryChart>
          <VictoryLine
            style={{
              data: {stroke: "#c43a31"},
              parent: {border: "1px solid #ccc"}
            }}
            data={[
              {x: 1, y: 2},
              {x: 2, y: 3},
              {x: 3, y: 5},
              {x: 4, y: 4},
              {x: 5, y: 7}
            ]}
          />
        </VictoryChart>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" align="left" component="h1" gutterBottom>
          Transaction log
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow key={block.name}>
                <TableCell component="th" scope="row">
                  Rent Price
                </TableCell>
                <TableCell align="right">{block.rent_price || 1000}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
