import * as yup from 'yup';
import YupFinalForm from '../../../libs/yup-final-form';

const validator = yup.object().shape({
  title: yup.string().required(),
  image_url: yup.string().required(),
  description: yup.string().required(),
  buy_price: yup.number().required(),
  rent_price: yup.number().required(),
});

export default values => YupFinalForm(values, validator);
