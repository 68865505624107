import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useWeb3React} from '@web3-react/core'
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {BlockItem} from "./index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  media: {},
  card: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  blockItem: {
    padding: 10,
  },
}));


/*
To test Blockchain integration with NetworkConnector,
we are going to extract info from DontButMeme's NTFs.

https://etherscan.io/address/0xe4605d46fd0b3f8329d936a8b258d69276cba264#readContract
function: uri(Id)

*/


// Web3 integration:

const ethers = require('ethers');


const MEME_CONTRACT_ABI = [{
  "inputs": [{"internalType": "address", "name": "_proxyRegistryAddress", "type": "address"}],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "constructor"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "_owner", "type": "address"}, {
    "indexed": true,
    "internalType": "address",
    "name": "_operator",
    "type": "address"
  }, {"indexed": false, "internalType": "bool", "name": "_approved", "type": "bool"}],
  "name": "ApprovalForAll",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "account", "type": "address"}],
  "name": "MinterAdded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "account", "type": "address"}],
  "name": "MinterRemoved",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "previousOwner", "type": "address"}, {
    "indexed": true,
    "internalType": "address",
    "name": "newOwner",
    "type": "address"
  }],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "_operator", "type": "address"}, {
    "indexed": true,
    "internalType": "address",
    "name": "_from",
    "type": "address"
  }, {"indexed": true, "internalType": "address", "name": "_to", "type": "address"}, {
    "indexed": false,
    "internalType": "uint256[]",
    "name": "_ids",
    "type": "uint256[]"
  }, {"indexed": false, "internalType": "uint256[]", "name": "_amounts", "type": "uint256[]"}],
  "name": "TransferBatch",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "_operator", "type": "address"}, {
    "indexed": true,
    "internalType": "address",
    "name": "_from",
    "type": "address"
  }, {"indexed": true, "internalType": "address", "name": "_to", "type": "address"}, {
    "indexed": false,
    "internalType": "uint256",
    "name": "_id",
    "type": "uint256"
  }, {"indexed": false, "internalType": "uint256", "name": "_amount", "type": "uint256"}],
  "name": "TransferSingle",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": false, "internalType": "string", "name": "_uri", "type": "string"}, {
    "indexed": true,
    "internalType": "uint256",
    "name": "_id",
    "type": "uint256"
  }],
  "name": "URI",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "account", "type": "address"}],
  "name": "WhitelistAdminAdded",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [{"indexed": true, "internalType": "address", "name": "account", "type": "address"}],
  "name": "WhitelistAdminRemoved",
  "type": "event"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "addMinter",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "addWhitelistAdmin",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "address", "name": "_owner", "type": "address"}, {
    "internalType": "uint256",
    "name": "_id",
    "type": "uint256"
  }],
  "name": "balanceOf",
  "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "address[]", "name": "_owners", "type": "address[]"}, {
    "internalType": "uint256[]",
    "name": "_ids",
    "type": "uint256[]"
  }],
  "name": "balanceOfBatch",
  "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "contractURI",
  "outputs": [{"internalType": "string", "name": "", "type": "string"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "uint256", "name": "_maxSupply", "type": "uint256"}, {
    "internalType": "uint256",
    "name": "_initialSupply",
    "type": "uint256"
  }, {"internalType": "string", "name": "_uri", "type": "string"}, {
    "internalType": "bytes",
    "name": "_data",
    "type": "bytes"
  }],
  "name": "create",
  "outputs": [{"internalType": "uint256", "name": "tokenId", "type": "uint256"}],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "name": "creators",
  "outputs": [{"internalType": "address", "name": "", "type": "address"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "address", "name": "_owner", "type": "address"}, {
    "internalType": "address",
    "name": "_operator",
    "type": "address"
  }],
  "name": "isApprovedForAll",
  "outputs": [{"internalType": "bool", "name": "isOperator", "type": "bool"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "isMinter",
  "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "isOwner",
  "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "isWhitelistAdmin",
  "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "_id", "type": "uint256"}],
  "name": "maxSupply",
  "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "_to", "type": "address"}, {
    "internalType": "uint256",
    "name": "_id",
    "type": "uint256"
  }, {"internalType": "uint256", "name": "_quantity", "type": "uint256"}, {
    "internalType": "bytes",
    "name": "_data",
    "type": "bytes"
  }],
  "name": "mint",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "name",
  "outputs": [{"internalType": "string", "name": "", "type": "string"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "owner",
  "outputs": [{"internalType": "address", "name": "", "type": "address"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "removeMinter",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
  "name": "removeWhitelistAdmin",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [],
  "name": "renounceMinter",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [],
  "name": "renounceWhitelistAdmin",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "_from", "type": "address"}, {
    "internalType": "address",
    "name": "_to",
    "type": "address"
  }, {"internalType": "uint256[]", "name": "_ids", "type": "uint256[]"}, {
    "internalType": "uint256[]",
    "name": "_amounts",
    "type": "uint256[]"
  }, {"internalType": "bytes", "name": "_data", "type": "bytes"}],
  "name": "safeBatchTransferFrom",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "_from", "type": "address"}, {
    "internalType": "address",
    "name": "_to",
    "type": "address"
  }, {"internalType": "uint256", "name": "_id", "type": "uint256"}, {
    "internalType": "uint256",
    "name": "_amount",
    "type": "uint256"
  }, {"internalType": "bytes", "name": "_data", "type": "bytes"}],
  "name": "safeTransferFrom",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "_operator", "type": "address"}, {
    "internalType": "bool",
    "name": "_approved",
    "type": "bool"
  }],
  "name": "setApprovalForAll",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "string", "name": "_newBaseMetadataURI", "type": "string"}],
  "name": "setBaseMetadataURI",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "bytes4", "name": "_interfaceID", "type": "bytes4"}],
  "name": "supportsInterface",
  "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [],
  "name": "symbol",
  "outputs": [{"internalType": "string", "name": "", "type": "string"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "name": "tokenMaxSupply",
  "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "name": "tokenSupply",
  "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "_id", "type": "uint256"}],
  "name": "totalSupply",
  "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
  "name": "transferOwnership",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": true,
  "inputs": [{"internalType": "uint256", "name": "_id", "type": "uint256"}],
  "name": "uri",
  "outputs": [{"internalType": "string", "name": "", "type": "string"}],
  "payable": false,
  "stateMutability": "view",
  "type": "function"
}]

const MEME_ADDRESS = "0xe4605d46fd0b3f8329d936a8b258d69276cba264"

export default ({block: initialBlocks}) => {
  const context = useWeb3React();
  const {
    connector,
    //library,
    chainId,
    account,
    //activate,
    // deactivate,
    // active,
    // error
  } = context;
  const [show, setshow] = useState(false);
  const [blocks, setblocks] = useState(initialBlocks);

  const [memeTitle, setMemeTitle] = React.useState('...');

  async function loadToken(tokenId) {
    var provider = ethers.getDefaultProvider(chainId);
    var contract = new ethers.Contract(MEME_ADDRESS, MEME_CONTRACT_ABI, provider);
    var memeUri = await contract.uri(tokenId);
    console.log("TokenId: ", tokenId, ", Uri: ", memeUri);
    //memeUri: https://api.dontbuymeme.com/memes/4
    return axios({
      method: 'get',
      url: memeUri,
    })
      .then((response) => setMemeTitle(response.data.name));

  }

  useEffect(() => {
    if (show) {
      setblocks(blocks.map(block => {
        Object.assign(block, {
          img: 'https://material-ui.com/static/images/grid-list/breakfast.jpg',
          title: memeTitle,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          rentPrice: 3,
          buyPrice: 5,
          url: 'https://www.google.com',
        }, block);
        loadToken(block.id);
        return block;
      }))
    }
  }, [show]);

  const classes = useStyles();

  if (show)
    return blocks.map(block => <BlockItem block={block}/>)
  else
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} className={classes.blockItem} container spacing={0} direction="row"
            justify="center" alignItems="center">
        <Button
          type="button"
          variant="contained"
          onClick={() => setshow(true)}
        >
          load {blocks.length > 1 ? `${blocks[0].id} ... ${blocks[blocks.length-1].id}` : blocks[0].id}
        </Button>
      </Grid>
    )
}
